type ENV = Record<string, string | undefined>;

const envMetaTags = Array.from(document.querySelectorAll("meta[itemprop=env]"));
const env: ENV = {};

envMetaTags.forEach((tag) => {
  const name = tag.getAttribute("name");
  const content = tag.getAttribute("content");
  if (!name || !content) return;
  env[name as keyof ENV] = content;
});

export default env;
